<template>
  <v-container class="container--fluid grid-list-md">
    <h2 class="mb-2">{{$t('route.xmls.view')}}</h2>
    <a href="https://pricecreator-rozetka.tatet.net/seller/xmlfiles/previewXmlLive">Ссылка</a><br>
    <a href="https://pricecreator-rozetka.tatet.net/seller/xml/reader">Ссылка</a><br>
  </v-container>
</template>

<script>
export default {
name: "viewXML"
}
</script>

<style scoped>

</style>
